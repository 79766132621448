@import "./colors.scss";

.header {
  // background-color: $header-bg;
  color: $header-text;
  background-image: url("./media/header-bg.png");
  background-position: center center;
  min-height: 250px;

  .bars {
    i {
      font-size: 2rem;
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  .logo {
    left: 0;
    right: 0;
    position: absolute;
    height: 190px;
    margin: 10px auto 0;
    animation: spin infinite 20s linear;
  }

  .logo-text {
    left: 0;
    right: 0;
    position: absolute;
    top: 75px;
    margin-left: auto;
    margin-right: auto;
    height: 64px;
    width: 217px;
  }

  .side-col {
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    i {
      padding: 0.5rem 1rem;
      font-size: 1.5rem;
    }

    .top {
      > div {
        width: 100%;
      }

      height: 130px;
      display: flex;
      align-items: center;

      .with-icon {
        justify-content: flex-end;
        align-items: center;
        display: flex;

        a {
          width: 8rem;
        }
      }

      a:hover {
        text-decoration: underline;
        color: greenyellow;
      }
    }

    .bottom {
      color: $main-text;
      font-size: 1.2rem;
      font-weight: 500;
      text-align: right;
      color: rgb(158, 156, 156);

      &.left {
        text-align: left;
      }
    }
  }
}

.main {
  background: linear-gradient(to bottom, white, $main-bg);
  padding: 2rem 0;
  // border-bottom: 1px solid lighten($main-bg, 5);
  // margin-bottom: 3rem;
  color: $main-text;

  a {
    color: $hilighte;
    text-decoration: underline;
  }

  h1 {
    font-size: 1.25rem;
    text-align: center;
    text-transform: uppercase;
  }

  .colx {
    display: block;
    margin: 2rem auto;
    height: 221px;
    width: 220px;
  }

  li {
    list-style-type: none;
  }

  li:before {
    content: "● ";
    padding-right: 0.5rem;
    margin-left: -1.5rem;
    color: $hilighte;
  }

  .hide {
    display: none;
  }

  @media (min-width: 1024px) {
    .hidden {
      display: none !important;
    }

    .hide {
      display: block;
      text-transform: uppercase;
      margin: 0 1rem 0 2.5rem;
      padding: 0.5rem 1rem;
      border: 3px double #9e9c9c;
      text-align: center;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.map {
  iframe {
    width: 100%;
    height: 40vh;
    border: none;
    border-top: 1px solid white;
  }
}

.footer {
  background-color: $footer-bg;
  color: $footer-text;
  padding: 1rem 0;

  a {
    text-decoration: underline;

    &:hover {
      color: greenyellow;
    }
  }

  ul {
    li {
      line-height: 160%;
    }
  }

  // font-size: 0.9rem;
  .with-icon {
    i {
      padding: 0.5rem 1rem 0.5rem 0;
      font-size: 1.5rem;
    }

    justify-content: flex-start;
    align-items: center;
    display: flex;

    a {
      width: 8rem;
    }
  }

  .copy {
    border-top: 1px solid lighten($footer-bg, 5);
    padding-top: 2rem;
    margin-top: 2rem;
    text-align: center;
    font-size: 0.9rem;
    color: darken($footer-text, 20);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.checksum-contaier {
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  align-items: center;
  h4,
  h5,
  h6 {
    text-align: center;
  }
  .file-upload {
    display: none;
  }

  .simple-label {
    margin: 1rem;
    font-size: inherit !important;
    font-weight: inherit !important;
    color: inherit !important;
    // display: inline !important;
    // text-decoration: underline;
    padding: 2rem 4rem;
    background: lighten(#344855, 65%);
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.25s;
    &:hover {
      background: lighten(#344855, 55%);
    }
  }

  .files-list {
    // font-weight: bold;
    // width: 100%;
    li {
      padding: 0.25rem;
      white-space: nowrap;
    }
  }
}
