body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.container-fluid {
  max-width: 1200px; }

a {
  text-decoration: none;
  color: inherit; }

#root {
  display: -webkit-flex;
  display: flex;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column; }
